import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { Auth } from 'aws-amplify';

ForgotPasswordSubmit.propTypes =
{
    email: PropTypes.string.isRequired,
    onSignIn: PropTypes.func.isRequired,
    onFormSwitch: PropTypes.func.isRequired,
};

function ForgotPasswordSubmit(props)
{
    const [email, setEmail] = useState(props.email);
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    function onForgotPasswordSubmitClick(event)
    {
        async function cognitoForgotPasswordSubmit()
        {
            try 
            {
                await Auth.forgotPasswordSubmit(email, code, newPassword);

                await cognitoSignIn();
            }
            catch(error)
            {
              setIsFormSubmitting(false);

              setErrorText(error.message);
            }
        }

        async function cognitoSignIn()
        {
            try 
            {
                const user = await Auth.signIn(email, newPassword);

                props.onSignIn(user);
            }
            catch(error)
            {
                setErrorText(error.message);
            }
        }

        if(event)
        {
            event.preventDefault();
        }

        setIsFormSubmitting(true);

        cognitoForgotPasswordSubmit();
    }

    return(
      <>
        {
            errorText && <Alert variant="danger">{errorText}</Alert>
        }
        <Card className="mb-2">
          <Card.Header className="smartcoupons-forgottenpassword text-white">Reset your Smart Coupons password</Card.Header>
          <Card.Body>
            <Form onSubmit={onForgotPasswordSubmitClick}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control className="pt-4 pb-4 pl-3 pr-3" type="email" autoComplete="username" placeholder="Enter your email address" value={email} onChange={e => setEmail(e.target.value)}/>
              </Form.Group>
              <Form.Group controlId="formBasicNumber">
                <Form.Label>Verification code</Form.Label>
                <Form.Control className="pt-4 pb-4 pl-3 pr-3" type="number" placeholder="Enter code" value={code} onChange={e => setCode(e.target.value)}/>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control className="pt-4 pb-4 pl-3 pr-3" type="password" autoComplete="new-password" placeholder="Enter your new password" value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                <Form.Text className="text-muted">Password must be at least 8 characters including a number, a lowercase letter, an uppercase letter and a symbol</Form.Text>
              </Form.Group>
              <Button className="btn-block smartcoupons-forgottenpassword" disabled={isFormSubmitting} size="lg" type="submit">
                {
                  isFormSubmitting ?
                  <Spinner animation="border" size="sm"/> :
                  "Submit"
                }
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Body className="text-center">
            <button type="button" className="link-button" onClick={(event) => props.onFormSwitch('signin', event)}>Back to Sign In</button>
          </Card.Body>
        </Card>
        <br/>
      </>
    );
}

export default ForgotPasswordSubmit;