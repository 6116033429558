import React from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus, faStore } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';


NoCoupons.propTypes =
{
    message: PropTypes.string.isRequired,
};

function NoCoupons(props)
{
    library.add(fab);

    return(
        <>
            <Row className="mt-3 mb-3">
                <Col><h3 className="text-center">{props.message}. Look out for campaigns...</h3></Col>
            </Row>
            <Row>
                <Col xl={4} lg={4} md={12} sm={12}>
                    <Card className="text-center border-0">
                        <Card.Body>
                            <Card.Text>
                                <FontAwesomeIcon icon={faBus} className="fa-10x text-danger" />
                            </Card.Text>
                            <h2>at Bus Stops</h2>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} lg={4} md={12} sm={12}>
                <Card className="text-center border-0">
                        <Card.Body>
                            <Card.Text>
                                <FontAwesomeIcon icon={faStore} className="fa-10x text-success" />
                            </Card.Text>
                            <h2>in Store</h2>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} lg={4} md={12} sm={12}>
                    <Card className="text-center border-0">
                        <Card.Body>
                            <Card.Text>
                                <FontAwesomeIcon icon={["fab", "twitter"]} className="fa-10x text-primary" />
                            </Card.Text>
                            <h2>on Social Media</h2>
                        </Card.Body>
                    </Card>                                                    
                </Col>
            </Row>
        </>
    );
}

export default NoCoupons;