import { API } from 'aws-amplify';

const PortalAPI =
{
    getUserDetails: async function(cognitoId)
    {
        const apiName = 'Web Portal API'
        const path = `/user/${cognitoId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // body: {} // OPTIONAL
        };

        try
        {
            const res = await API.get(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    updateUserDetails: async function(cognitoId, userDetails)
    {
        const apiName = 'Web Portal API'
        const path = `/user/${cognitoId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: userDetails,
        };

        try
        {
            const res = await API.put(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    getCampaign: async function(campaignId)
    {
        const apiName = 'Web Portal API'
        const path = `/campaign/${campaignId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // body: {} // OPTIONAL
        };

        try
        {
            const res = await API.get(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    getCampaignGroup: async function(campaignGroupId)
    {
        const apiName = 'Web Portal API'
        const path = `/campaign-group/${campaignGroupId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // body: {} // OPTIONAL
        };

        try
        {
            const res = await API.get(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    // pass a base64 encoded json string of an array of campaignIds to this function
    getCampaigns: async function(campaignIds)
    {
        const apiName = 'Web Portal API'
        const path = `/campaigns/${campaignIds}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // body: {} // OPTIONAL
        };

        try
        {
            const res = await API.get(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    getLatestOffers: async function(cognitoId)
    {
        const apiName = 'Web Portal API'
        const path = `/latest-offers/${cognitoId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // body: {} // OPTIONAL
        };

        try
        {
            const res = await API.get(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    getWallet: async function(cognitoId)
    {
        const apiName = 'Web Portal API'
        const path = `/wallet/${cognitoId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // body: {} // OPTIONAL
        };

        try
        {
            const res = await API.get(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    addCampaignToWallet: async function(cognitoId, campaignId)
    {
        const apiName = 'Web Portal API'
        const path = `/wallet/${cognitoId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body:
            {
                campaign_id: campaignId,
            },
        };

        try
        {
            const res = await API.post(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    addCampaignGroupToWallet: async function(cognitoId, campaignGroupId)
    {
        const apiName = 'Web Portal API'
        const path = `/wallet/${cognitoId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body:
            {
                campaign_group_id: campaignGroupId,
            },
        };

        try
        {
            const res = await API.post(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    addCampaignsToWallet: async function(cognitoId, campaignIds, parentCampaignGroupId)
    {
        const apiName = 'Web Portal API'
        const path = `/wallet/${cognitoId}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body:
            {
                campaign_ids: campaignIds,
                parent_campaign_group_id: parentCampaignGroupId,
            },
        };

        try
        {
            const res = await API.post(apiName, path, myInit);

            return(res.data);
        }
        catch(err)
        {
            if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },


    getSiteGeoPromise: null,

    getSiteGeo: async function(latitude, longitude, radiusInMeters)
    {
        const apiName = 'Web Portal API'
        const path = `/geo/site?latitude=${latitude}&longitude=${longitude}&radiusInMeters=${radiusInMeters}`;
        const myInit =
        {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // body: {} // OPTIONAL
        };

        // we store a reference to the last api call so we can cancel it again if we need to
        this.getSiteGeoPromise = API.get(apiName, path, myInit);

        try
        {
            const res = await this.getSiteGeoPromise;

            return(res.data);
        }
        catch(err)
        {
            if(err.message) // this will be triggered if the call is cancelled
            {
                // do nothing - this is legit, and we don't really care for a response
                return;
            }
            else if(err.response.data)
            {
                return(err.response.data);
            }

            throw(err);
        }
    },

    cancelGetSiteGeo: function()
    {
        API.cancel(this.getSiteGeoPromise, "Cancelled GetSiteGeo call");
    },
};

export default PortalAPI;