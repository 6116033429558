import React from 'react';
import { Auth } from 'aws-amplify';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSubmit from './ForgotPasswordSubmit';

const FormState =
{
    SignIn: 'signin',
    SignUp: 'signup',
    ForgotPassword: 'forgotpassword',
    ForgotPasswordSubmit: 'forgotpasswordsubmit',
    SignedIn: 'signedin',
};


function PortalAuth(props)
{
    const [formState, setFormState] = React.useState(FormState.SignUp);
    // const [formState, setFormState] = React.useState(FormState.SignIn);
    const [email, setEmail] = React.useState("");

    const onUserChange = props.onUserChange;

    function handleSignIn(user)
    {
        if(user !== null)
        {
            setFormState(FormState.SignedIn);
        }

        onUserChange(user);
    }

    React.useEffect(function()
    {
        async function checkAuthenticatedUser()
        {
            try
            {
                const authenticatedUser = await Auth.currentAuthenticatedUser(); 

                if(authenticatedUser)
                {
                    handleSignIn(authenticatedUser);
                }
            }
            catch(error)
            {
                // no authenticated user
                handleSignIn(null);
            }
        }
        
        checkAuthenticatedUser();
        
        // eslint-disable-next-line
    }, []); // only run once on the first page load - the linter complains, but by trying to fix it, I cause an infinite loop, whereas it works like this :-(

    function handleFormSwitch(formState)
    {
        setFormState(formState);
    }

    function handleEmailChange(email)
    {
        setEmail(email);
    }

    function getFormComponent()
    {
        let formComponent;

        if(formState === FormState.SignIn)
        {
            formComponent = <SignIn onSignIn={handleSignIn} onFormSwitch={handleFormSwitch}></SignIn>;
        }
        else if(formState === FormState.SignUp)
        {
            formComponent = <SignUp onSignIn={handleSignIn} onFormSwitch={handleFormSwitch} />;
        }
        else if(formState === FormState.ForgotPassword)
        {
            formComponent = <ForgotPassword onFormSwitch={handleFormSwitch} onEmailChange={handleEmailChange}/>;
        }
        else if(formState === FormState.ForgotPasswordSubmit)
        {
            formComponent = <ForgotPasswordSubmit onSignIn={handleSignIn} onFormSwitch={handleFormSwitch} email={email}/>;
        }
        else if(formState === FormState.SignedIn) // if signed in we don't need to wrap it so just return the wrapped component
        {
            return(props.children);
        }

        return(
            <Container>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        {formComponent}
                    </Col>
                </Row>
            </Container>
        );
    }


    return(getFormComponent());
}

export default PortalAuth;