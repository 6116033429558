import React from 'react';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

function SaviCoupon()
{
    let { pin } = useParams();

    const couponBarcode = atob(pin);

    return(
        <Container>
            <Row className="justify-content-md-center">
                <Col xl={8} lg={8} md={8} sm={12} className="mb-4">
                    <Card>
                        <Row>
                            <Col md={4}>
                                <Image
                                    src="https://prod-tactus-cdn.s3.eu-west-1.amazonaws.com/coupon_campaign/fea6b6ef-3248-4c7b-8b5f-279ae38c9338/Galaxy_portrait.jpg"
                                    style={{maxWidth: '100%', paddingRight: '0px'}}/>
                            </Col>
                            <Col md={8}>
                                <Card.Body>
                                    <Card.Title className="mb-0" style={{color: '#CE3A67', fontSize: '24px'}}>UP TO</Card.Title>
                                    <Card.Subtitle className="mb-4" style={{color: '#CE3A67', fontSize: '48px', fontWeight: 'bold'}}>£1.50 coupon</Card.Subtitle>
                                    <Card.Text style={{color: '#4E272D', fontSize: '20px', fontWeight: 'bold'}}>
                                        Claim your free Galaxy&reg; chocolate!
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={12}>
                                <div className="barcode">{couponBarcode}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <p className="text-center">This barcode can only be scanned once</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default SaviCoupon;