import React from 'react';
import
{
    useHistory,
}
from 'react-router-dom';
import update from 'immutability-helper';
// import { useCookies } from 'react-cookie';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import CouponCard from './CouponCard';
import CouponModal from './CouponModal';
import PortalAPI from '../PortalAPI';
import ScrollGuide from './ScrollGuide';
import PortalAuth from './PortalAuth';
import NoCoupons from './NoCoupons';


function LatestOffers()
{
    const [user, setUser] = React.useState();
    const [latestOffers, setLatestOffers] = React.useState([]);
    const [latestOffersLoaded, setLatestOffersLoaded] = React.useState(false);
    const [latestOffersAlert, setLatestOffersAlert] = React.useState("");
    // const [, setCookie] = useCookies(['addSelectedCampaigns']);
    const [selectedCoupon, setSelectedCoupon] = React.useState({
        name: null,
        description: null,
        imageUrl: null,
        maxUserRedeem: null,
        terms: null,
        expireDate: null,
        redeemCount: null,
        isExpired: null
    });
    const [showCouponModal, setShowCouponModal] = React.useState(false);

    let history = useHistory();

    React.useEffect(() =>
    {
        if(user != null)
        {
            const region = user.pool.userPoolId.substring(0, user.pool.userPoolId.indexOf('_')); // strip the region of the userpool out from the userpoolid
            const cognitoId = `${region}:${user.attributes.sub}`;

            PortalAPI.getLatestOffers(cognitoId).then(function(response)
            {
                setLatestOffersLoaded(true);

                if(response.code === 200)
                {
                    let latestOffers = response.payload;

                    latestOffers.sort(function(a, b)
                    {
                        if(a.priority !== b.priority) return(a.priority < b.priority) ? 1 : -1;
                        return(a.name < b.name) ? -1 : 1;
                    });

                    for(let campaign of latestOffers)
                    {
                        campaign.selected = false; // we will need this property later to keep a tally of all selected coupons
                    }

                    setLatestOffers(latestOffers);
                }
                else if(response.code === 404)
                {
                    setLatestOffersLoaded(true);
                }
                else
                {
                    console.log('error!', response);
                    // deal with errors

                    setLatestOffersAlert("An unknown error has occurred when trying to load a campaign group");
                }
            }).catch(function(err)
            {
                console.log('getLatestOffers error:', err);
            });
        }
    }, [user]);


    function handleAddAllCouponsClick()
    {
        let couponIds = [];

        for(let coupon of latestOffers)
        {
            couponIds.push(coupon.campaignId);
        }

        const couponsB64 = window.btoa(JSON.stringify(couponIds)); // base 64 encode so we can pass as url param

        // setCookie('addSelectedCampaigns', couponsB64,
        // {
        //     path: '/',
        //     expires: new Date('2038-01-01'),
        // });

        history.push(`/wallet?addCampaigns=${couponsB64}`);
    }


    function handleAddSelectedCouponsClick()
    {
        let selectedCouponIds = [];

        for(let coupon of latestOffers)
        {
            if(coupon.selected)
            {
                selectedCouponIds.push(coupon.campaignId);
            }
        }

        const selectedCouponsB64 = window.btoa(JSON.stringify(selectedCouponIds)); // base 64 encode so we can pass as url param

        // setCookie('addSelectedCampaigns', selectedCouponsB64,
        // {
        //     path: '/',
        //     expires: new Date('2038-01-01'),
        // });

        history.push(`/wallet?addCampaigns=${selectedCouponsB64}`);
    }


    function handleSelectCouponClick(coupon)
    {
        // find the matching coupon from the wallet so we can 'update' it
        const couponIndex = latestOffers.findIndex(function(latestOffersCoupon)
        {
            return(coupon.campaignId === latestOffersCoupon.campaignId);
        });

        // create a new copy of the immutable campaignGroup with the relevant entry updated
        const newLatestOffersData = update(latestOffers, {[couponIndex] : {$toggle: ['selected']}});

        setLatestOffers(newLatestOffersData);
    }


    function getSelectedCouponCount()
    {
        const count = latestOffers.filter((campaign) => campaign.selected === true).length;

        return(count);
    }


    function handleDetailsClick(coupon)
    {
        setShowCouponModal(true);
        setSelectedCoupon(coupon);
    }

    function handleModalHide()
    {
        setShowCouponModal(false);
    }

    function handleUserChange(newUser)
    {
        setUser(newUser);
    }

    const coupons = latestOffers.map(function(coupon)
    {
        return(
            <Col key={coupon.campaignId} xl={4} lg={6} md={6} sm={12} className="mb-4">
                <div className="card-container h-100">
                    <div>
                        <div>
                            <CouponCard coupon={coupon} onDetailsClick={handleDetailsClick.bind(this, coupon)} allowSelection onSelectCouponClick={handleSelectCouponClick} showAgeCheck/>
                        </div>
                    </div>                                               
                </div>
            </Col>
        );
    });

    return(
        <PortalAuth onUserChange={handleUserChange}>
            <CouponModal coupon={selectedCoupon} show={showCouponModal} onHide={handleModalHide}/>
            <Container>
                {
                    latestOffersAlert ?                    
                    <Alert variant="danger">{latestOffersAlert}</Alert> :
                    <>
                        {
                            latestOffersLoaded ?
                            <>
                                {
                                    coupons.length > 0 ?
                                        getSelectedCouponCount() > 0 ?
                                        <Alert variant="light" className="d-flex align-items-center justify-content-between p-2 sticky">
                                            <span>You have selected {getSelectedCouponCount()} {getSelectedCouponCount() === 1 ? "Coupon." : "Coupons."} Click to add them to your wallet.</span>
                                            <Button variant="success" onClick={() => handleAddSelectedCouponsClick()}><FontAwesomeIcon icon={faPlusCircle}/> Add Selected Coupons to your Wallet</Button>
                                        </Alert> :
                                        <Alert variant="light" className="d-flex align-items-center justify-content-between p-2 sticky">
                                            <span>Select the coupons below you would like to add to your wallet, or add all of them.</span>
                                            <Button variant="primary" onClick={() => handleAddAllCouponsClick()}><FontAwesomeIcon icon={faPlusCircle}/> Add All Coupons to your Wallet</Button>
                                        </Alert> :
                                        null
                                }
                            </> :
                            <p>Loading Latest Offers...</p>
                        }
                        {
                            latestOffersLoaded ?
                            (
                                coupons.length > 0 ?
                                <Row>
                                {coupons}
                                </Row> :
                                <NoCoupons message="There are no offers currently available for you" />
                            ) :
                            null
                        }
                    </>
                }
            </Container>

            <ScrollGuide />
        </PortalAuth>
    );
}

export default LatestOffers;