import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { Auth } from 'aws-amplify';

SignIn.propTypes =
{
    onSignIn: PropTypes.func.isRequired,
    onFormSwitch: PropTypes.func.isRequired,
};

function SignIn(props)
{
    const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [password, setPassword] = useState('L.$EL!Q7z]%UfkK.');
    const [errorText, setErrorText] = useState("");
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    function onSignInClick(event)
    {
        async function cognitoSignIn()
        {
            try 
            {
                const user = await Auth.signIn(email, password);

                props.onSignIn(user);
            }
            catch(error)
            {
                setIsFormSubmitting(false);
                props.onSignIn(null);
                setErrorText(error.message);
                window.scrollTo(0, 0);
            }
        }

        if(event)
        {
            event.preventDefault();
        }

        setIsFormSubmitting(true);

        cognitoSignIn();
    }


    function handleFederatedSignIn(provider)
    {
        async function federatedSignIn()
        {
            try
            {
                await Auth.federatedSignIn({provider: provider});
            }
            catch(error)
            {
                console.log('fed sign in error:', error);
            }
        }

        federatedSignIn();
    }

    return(
      <>
        {
            errorText && <Alert variant="danger">{errorText}</Alert>
        }
        <Card className="mb-2">
          <Card.Header className="smartcoupons-signin text-white">Sign in to Smart Coupons</Card.Header>
          <Card.Body>
            <Form onSubmit={onSignInClick}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control className="pt-4 pb-4 pl-3 pr-3" type="email" autoComplete="username" placeholder="Enter your email address" value={email} onChange={e => setEmail(e.target.value)}/>
              </Form.Group>
              <Form.Group controlId="formBasicPassword" style={{display: 'none'}}>
                <Form.Label>Password</Form.Label>
                <Form.Control className="pt-4 pb-4 pl-3 pr-3" type="text" autoComplete="current-password" placeholder="Enter your password" value={password} onChange={e => setPassword(e.target.value)}/>
              </Form.Group>
              <Form.Group style={{display: 'none'}}>
                <Form.Label>Forgot your password? <button type="button" className="link-button" onClick={(event) => props.onFormSwitch('forgotpassword', event)}>Reset password</button></Form.Label>
              </Form.Group>
              <Button className="btn-block smartcoupons-signin" variant="warning" disabled={isFormSubmitting} size="lg" type="submit">
                {
                  isFormSubmitting ?
                  <Spinner animation="border" size="sm" /> :
                  "Sign In"
                }
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Card className="mb-2">
          <Card.Body className="text-center">
            <span style={{fontSize: '1.2rem'}}> 
              New to Smart Coupons?
              <br />
              <button type="button" className="link-button" onClick={(event) => props.onFormSwitch('signup', event)}>Sign Up</button>
            </span>
          </Card.Body>
        </Card>
        <Card className="mb-4" style={{display: 'none'}}>
          <Card.Body>
            <Button className="btn-block social-button facebook-button" size="lg" variant="primary" type="submit" onClick={() => handleFederatedSignIn('Facebook')}>
              <svg className="social-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216" color="#ffffff">
                <path fill="#ffffff" d="
                    M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
                    11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
                    11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
                    15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
                    11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"></path>
              </svg>
              Sign In with Facebook
            </Button>
            <Button className="btn-block social-button google-button" variant="primary" size="lg" type="submit" onClick={() => handleFederatedSignIn('Google')}>
              <svg className="social-logo" viewBox="0 0 256 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                <path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path>
                <path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path>
                <path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path>
                <path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EA4335"></path>
              </svg>
              Sign In with Google
            </Button>
          </Card.Body>
        </Card>
      </>
    );
}

export default SignIn;