import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CouponModalCard from './CouponModalCard';
import ParticipatingStoresCard from './ParticipatingStoresCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


CouponModal.propTypes =
{
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    coupon: PropTypes.object.isRequired,
};

function CouponModal(props)
{
    return(
        <>
            {
                props.show &&
                <Button className="modalCloseButton" variant="danger" onClick={props.onHide}><FontAwesomeIcon className="fa-3x" icon={faTimes}/></Button>
            }
            <Modal {...props} size="xl" centered className="border-0">
                <Modal.Header className="smartcoupons-modal text-white">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.coupon.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12} xl={4} className="mb-2">
                                <CouponModalCard coupon={props.coupon} />
                            </Col>
                            <Col xs={12} md={12} lg={12} xl={8} className="mb-2">
                                <ParticipatingStoresCard />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CouponModal;