import React from 'react';
import Container from 'react-bootstrap/Container';

function TermsOfService()
{
    return(
        <Container>
            <h2>Terms of Service</h2>

            <h3>1 – Introduction</h3>
            <ol type="i">
                <li>This user agreement (the Agreement) is a legal agreement between you, the customer (whom we refer to as &quot;you&quot;, &quot;your&quot; or the &quot;customer&quot;) and us, <strong>I-TG International Services Limited</strong>, trading as In-Touch (whom we refer to as &quot;In-Touch&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot; or &quot;the Company&quot;). We are the operator of this Site and provider of a range of services related to this Site. In-Touch is a company incorporated in the UK with registered number 12038010 (the Company).</li>
                <li>In-Touch provide a platform (including this Site, other Sites, and mobile applications) where we, our partners and other sellers (collectively, “Sellers”) can list offers for Vouchers, deals, goods, loyalty and other products (collectively, “Items”). As a platform provider, we help facilitate transactions for Items on our platform. We are never a buyer and usually not the Seller; we provide a venue for Sellers and you to negotiate and complete transactions. This means that in addition to this Agreement, you will have other contractual relationships with different legal entities when you interact with the Site.</li>
                <li>This Agreement governs your use of the In-Touch platform including the In-Touch free download applications for Android, Windows Mobile and iOS (iPhone) respectively (collectively the App), and governs your use of the App.</li>
                <li>You will also be required to accept this Agreement when you enter the ‘My Account’ section of the App and provide your personal details for use in the My Account feature within the App (<strong>Account Information</strong>). Completing the My Account section allows you to use the features described in section 2 below. Should you decline the acceptance of this Agreement these aspects of the App will not be available to you.</li>
                <li>By using the platform including the App you agree that you have read and understood the terms and conditions of this Agreement and you acknowledge that these terms and conditions in their entirety shall apply to you.</li>
                <li>We reserve the right to amend, modify, update and change any of the terms of this Agreement. We advise you to check for updates on a regular basis. Any material changes to the Agreement will be notified to you through the messaging features of the App. Your continued use of the App will be deemed to constitute your acceptance of such changes.</li>
            </ol>

            <hr />

            <h3>2 - Registering with us</h3>
            <ol type="i">
                <li>Subject to the particular functionality of our platform and Site at any time, you may or may not need to Register to use much of the functionality and or to access many of the Services.  By Registering an account will be set up.  Your Account Information details will be sent to our In-Touch server. The Company will not store any passwords except locally on your device.</li>
                <li>The Privacy Policy, which is accessible through this Site, our other Sites and the App and forms an integral part of this Agreement, describes how we collect and use your Account Information.</li>
                <li>Where relevant we reserve the right to use the Account Information in order to contact you in the future in relation to marketing any other products or services offered by us, any of its related group companies, partners or participating merchants, unless you have opted out of such use in accordance with our Privacy Policy.</li>
            </ol>

            <hr />

            <h3>3 – Our Role</h3>
            <ol type="i">
                <li>In all circumstances when you purchase goods or services from participating merchants, the contract for the sale of those goods or services will be between you and the merchant.</li>
                <li>You understand and agree that the Company shall not be responsible for the outcome of your transactions with external merchants via the App, coupons or other third party content. We do not have control, nor shall we be liable for, the legality of, or the use of, the goods and services that are part of the transaction whilst using the App, coupons ot other third party content.</li>
                <li>It is the responsibility of you and the relevant merchant, not us, to resolve any dispute or claim raised by you relating to any transactions made via the App, coupon or other third party content (a Customer Claim). Where you have a dispute with a merchant, we will provide information we hold which may assist resolution and otherwise attempt to assist you as far as we consider practicable. We may charge you an administration fee to cover our reasonable costs of providing any such assistance.</li>
                <li>When you register with the In-Touch platform to receive coupons we will set up an Account to enable the download and use of the available coupon.</li>
                <li>When you register with the App, we will set up an Account to record points earned or redeemed by each Collector for each Retail Store.</li>
                <li>All Collectors must have a resident UK, Channel Island or Isle of Man address. Changes of address must be notified to us. Collectors must be over 18. </li>
                <li>In-Touch has Retail Stores through whom you earn points and / or redeem coupons. These Stores are identified within the platform or the App.</li>
                <li>For the App, we award points when one of our Stores tells us a Collector has undertaken a relevant transaction with them. For the purpose of the App, In-Touch Stores are companies with whom a Collector can redeem points to obtain goods and services or discounts (“Rewards”). These participating companies may change – an up to date list is available from us at any time within the In-Touch app. Stores determine the particular transactions on which points can be earned and the number of points to be earned. There is a set number of points needed to obtain a Reward. Each of these will change but in most cases there will be a base offer with further bonus offers that come and go. We cannot control every specific change in the participating companies or their offers.</li>
                <li>All Rewards are subject to availability. Transactions on which points are earned or redeemed are made directly with the relevant Store and we have no responsibility for the delivery, standard, quality or otherwise of any goods and services received or supplied or the failure of a Store to honour a redemption or make a specific Reward available. These transactions and all Rewards are subject to all applicable legal rules and the terms and conditions (including booking requirements, cancellation restrictions, return conditions, warranties and limitations of liability) of the Store. When points are redeemed we may act as intermediary between the relevant Collector and the Store.</li>
                <li>Points cannot be redeemed until credited to an In-Touch Account. Stores will take different periods to notify us of points to be credited. We are not responsible for a failure, delay or error by a Store notifying us of points to be credited.</li>
                <li>Points have no expiry date but are lost if the relevant In-Touch Account is closed or the In-Touch programme ends. Where points are redeemed for a Voucher carrying a specified cash discount or benefit, the Voucher will have an expiry date specified on it and will be subject to any other terms and conditions stated on the Voucher or otherwise publicised.</li>
                <li>Redeemed points cannot be used again. If a transaction on which points are issued or redeemed is cancelled, reversed or not completed, we will reverse the associated points movement when instructed by the relevant Store. If insufficient points are available, or we suspect fraud or misconduct, Rewards may be refused or cancelled.</li>
                <li>A Collector who continues to collect will get a regular update of the points position as we know it on the date we issue the update in respect of his/her Account including the points collected and redeemed by such Collector. Account information is also available from us. We will correct an In-Touch Account if it is shown to our reasonable satisfaction to be wrong but, unless there are clear records showing this, our decision is final.</li>
                <li>Points can only be earned, held, transferred or redeemed as set out in these Rules. Any other use, award, sale, exchange or transfer of points, or attempt to do so, is a serious breach of these Rules. Any points not earned and held in accordance with these Rules will be invalid and cannot be redeemed for Rewards. Any such points on an In-Touch Account will be deducted and, if they are redeemed, we will ask the Supplier to cancel the relevant Rewards and we may take other appropriate action. Points from more than one In-Touch Account cannot be combined to redeem for the same Reward. Points have no cash value.</li>
                <li>Anyone with an In-Touch App Account can redeem points from that account for any available Reward.</li>
                <li>Our Privacy Policy (which is part of these Rules) sets out the information that we will gather on Collectors, how a Collector uses his/her Account and earns and redeems points, how this information will be used and who it may be shared with. It is included within the In-Touch app, on certain other materials we publish and is available from us. We will comply with our Privacy Statement – every Collector should read it carefully. If a Collector notifies us that they do not wish to receive marketing information from us or be contacted by us for promotional purposes in connection with In-Touch and/or by participating companies we will comply with such request and pass on any relevant request to participating companies.</li>
                <li>We may close any In-Touch Account on which no points have been earned or redeemed for a continuous period of at least 12 months. We may also, on notifying the Collector, immediately suspend or terminate the rights of any Collector, and/or close any relevant In-Touch Account, if they breach these Rules, if we reasonably believe that they have dealt with points in a manner not permitted by these Rules or any applicable Store’s terms and conditions, if there is any theft from or misconduct in connection with us, or any Store, if they supply false or misleading information to us or if they are abusive or offensive to any member of our staff. A Collector can close his/her In-Touch Account at any time by notifying us. If an In-Touch Account is closed the rights to redeem points from that In-Touch Account are lost.</li>
                <li>We may make changes to these Rules and will give the Collector as much notice as we reasonably can. Earning or redeeming points on an In-Touch Account will constitute acceptance of the revised Rules. We may suspend or terminate In-Touch but will give as much notice as we reasonably can before we do so. If this happens all In-Touch Accounts will be suspended or terminated. If we sell or transfer In-Touch to another company we may transfer all of our rights and obligations under these Rules without any further consent and may disclose or transfer all information we hold about  Collectors to a prospective or actual new owner. Such a disclosure or transfer will not alter the rights of such Collectors in respect of the use that can be made of such information by such other company.</li>
                <li>We will only be liable to a Collector who suffers loss as a result of our breach of these Rules and, if so, our sole liability will be to credit to the relevant In-Touch Account any points which have been wrongly deducted or should have been credited but were not. These Rules shall not exclude or limit our liability for death or personal injury caused by our negligence. These Rules prevail in the event of any conflict or inconsistency with any other communications, including advertising or promotional materials.</li>
            </ol>

            <hr />

            <h3>4 – Charges</h3>
            <ol type="i">
                <li>We do not charge you to use this platform or App. However, your mobile network operator may charge you to access this platform or App and these charges may vary if you access the platform or App when abroad. You are responsible for these charges.</li>
            </ol>
            <hr />

            <h3>5 – Termination/Suspension of your Access</h3>
            <ol type="i">
                <li>We may suspend or terminate your access to the platform or App or to the features within the platform or App without notice where it is reasonable for us to do so and specifically if:</li>
                <dl>
                <dd>for any reason we decide to discontinue to provide the platform or App</dd>
                <dd>we believe that you have breached any of the terms of this Agreement</dd>
                <dd>your use of the platform or App has been in any way improper or breaches the spirit of this Agreement</dd>
                <dd>we reasonably believe use of the platform or App may be at risk of fraud or misuse</dd>
                <dd>our information technology infrastructure has failed, is experiencing outages or requires maintenance</dd>
                <dd>ordered or recommended to do so by the security services, court or a relevant regulatory authority</dd>
                </dl>
            </ol>

            <hr />

            <h3>6 – Your use of the platform and / or App</h3>
            <ol type="i">
            <li>You must not:</li>
            <dl>
                <dd>(I)	copy or reproduce all or any part of the platform or App</dd>
                <dd>alter, modify or adapt all or any part of the platform or App</dd>
                <dd>remove or tamper with any copyright notice attached to or contained within the platform or App</dd>
                <dd>carry out reverse engineering of the platform or App</dd>
            </dl>
            <li>You represent and warrant to us that:</li>
            <dl>
                <dd>all Account Information provided to us is true and accurate in every respect and you will keep all such information up to date during the term of this Agreement</dd>
                <dd>you will only use the platform or App for your own purposes and not on behalf of any other person or entity</dd>
                <dd>you and all payments initiated by you will comply with all laws, rules, and regulations</dd>
                <dd>you will not use the platform or App, directly or indirectly, for any fraudulent undertaking or in any manner so as to interfere with the functionality of the platform or App</dd>
            </dl>
            </ol>

            <hr />

            <h3>7 – Intellectual Property</h3>
            <ol type="i">
                <li>The Company hereby grants you the non-exclusive, non-transferable right to use our platform and App, including the copyright and all intellectual property rights therein, in connection with the platform and App and in accordance with this Agreement.</li>
                <li>The platform and App and all content derived from the platform and App is protected by copyright and/or other intellectual property rights. You hereby acknowledge that by using the platform and App you obtain no rights in the software, trademarks or content related to the platform and App and you may only use the same in complete accordance with this Agreement.</li>
            </ol>

            <hr />

            <h3>8 – NO WARRANTY</h3>
            <ol type="i">
                <li>THE PLATFORM AND APP IS PROVIDED “AS IS”. WE MAKE NO WARRANTY OR REPRESENTATION, WHETHER EXPRESS OR IMPLIED, IN RELATION TO THE SATISFACTORY QUALITY, FITNESS FOR PURPOSE, COMPLETENESS OR ACCURACY OF THE SERVICE OFFERED THROUGH THE PLATFORM AND APP.</li>
                <li>WE MAKE NO WARRANTY THAT THE OPERATION OF THE APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SOFTWARE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR BUGS, OR REPRESENT THE FULL FUNCTIONALITY, ACCURACY, RELIABILITY OF THE MATERIALS THEREIN.</li>
            </ol>

            <hr />

            <h3>9 – Limitations of Liability</h3>
            <ol type="i">
                <li>This section 9 sets out the entire liability of the parties (including any liability for the acts or omissions of their respective employees, agents and sub-contractors) to each other in respect of: any breach of this Agreement; any use made by you or our affiliates of the platform or App or any part of it; and any representation, statement or tortious act or omission (including negligence) arising under or in connection with this Agreement.</li>
                <li>We shall not be liable in contract, tort, negligence, or otherwise, for any loss or damage whatsoever arising from or in any way connected with your interaction and/or transaction with any third-party website via the platform or App. We are not responsible for the content of any linked sites.</li>
                <li>You confirm that we shall not be liable to you or any third party for any modification to, suspension of, or discontinuance of the platform or App.</li>
                <li>Nothing in this Agreement shall limit or exclude the liability of either party for death or personal injury resulting from negligence; fraud or fraudulent misrepresentation; the indemnification obligations referred to in section 10; or any other liability that cannot be excluded by applicable law.</li>
                <li>Without prejudice to section 9(iv):</li>
                <dl>
                    <dd>our sole liability will be to credit to the relevant In-Touch Account any points which have been wrongly deducted or should have been credited but were not. These Rules shall not exclude or limit our liability for death or personal injury caused by our negligence. These Rules prevail in the event of any conflict or inconsistency with any other communications, including advertising or promotional materials.</dd>
                    <dd>neither party shall be liable to the other, whether in contract, tort (including for negligence) or restitution, or for breach of statutory duty or misrepresentation, or otherwise for any: (i) losses that are not reasonably foreseeable; or (ii) special, indirect or consequential damage or loss of any kind whatsoever, in each case that arises under or in connection with this Agreement.</dd>
                    <dd>No party shall be liable for any delay in or failure to perform obligations they have undertaken in these terms as a result of events beyond their reasonable control which cannot reasonably be avoided or overcome, while such events persist.</dd>
                </dl>
            </ol>

            <hr />

            <h3>10 – Indemnity</h3>
            <ol type="i">
                  <li>You will indemnify us against any losses we suffer as a result of claims made or threatened against either of us as a result of your use of the platform and App in connection with criminal or other illegal activities.</li>
            </ol>
    
            <hr />
    
            <h3>11 – Language and Notices</h3>
            <ol type="i">
                <li>This Agreement has been drafted in the English language. In the event of any discrepancy between the meanings of any translated versions of this Agreement and the English language version, the meaning of the English language version shall prevail. You agree that we will communicate with you and you will communicate with us in English during the term of this Agreement.</li>
                <li>When you use the App or send e-mails to us, you are communicating with us electronically. By registering for the platform and App and accepting the terms of this Agreement, you affirmatively consent to receive notices electronically from us. We may provide in electronic format all communications including, without limitation. agreements related to the platform and App, amendments or changes to such agreements or any applicable policies, disclosures, notices, payment information, statements, responses to claims and other customer communications that we may be required to provide to you by law (collectively, Communications). Communications may be posted to the platform and App and sent by e-mail to the e-mail registered in your Account Information. All Communications will be deemed to be in “writing” and received or accessed by you. You are responsible for printing, storing and maintaining your own records of <strong>Communications</strong>, including copies of this Agreement. This condition does not affect your statutory rights. You can contact us about the platform and App by sending an email to <a href="mailto:marketing@in-touch-group.com">marketing@in-touch-group.com</a>.</li>
            </ol>

            <hr />

            <h3>12 – System complaints and Dispute Resolution</h3>
            <ol type="i">
                <li>If you wish to make a complaint about the platform and App, you can email <a href="mailto:marketing@in-touch-group.com">marketing@in-touch-group.com</a> with brief details of your complaint. Our customer service staff will acknowledge your complaint by email within 1 business day. They will then investigate and send you an initial response, having had access to an officer with the authority to settle the complaint (including, where appropriate, an offer of redress). Where appropriate, the member of staff investigating the complaint will not be any staff member who was directly involved in the subject matter of the complaint. This should take no longer than five business days, but most complaints may well be resolved by close of business on the business day after the complaint is received. If the complaint is not resolved by close of business on the business day after the complaint is received, we will send you a copy of this complaints handling procedure.</li>
                <li>If you are not satisfied by our response, you must contact the Customer Services Manager, who will respond by email within a further five business days.</li>
                <li>If you are not satisfied with the response from the Customer Services Manager, you can email <a href="mailto:office@in-touch-group.com">office@in-touch-group.com</a>, enclosing the responses already given to it. That email will then be referred to the Managing Director, who will respond by email within a final five business days.</li>
                <li>Within four weeks after receiving a complaint, we will send you either a final response or a response which explains why we are not in a position to resolve the complaint and indicates when we will make further contact.</li>
            </ol>

            <hr />

            <h3>13 – General</h3>
            <ol type="i">
                <li>We reserve the right to modify or terminate the platform and App at any time, without notice, and we will not be liable to you as a result of any such action.</li>
                <li>If any part of this Agreement shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed to be severable from the rest of this Agreement and shall not affect the validity and enforceability of any of the remaining provisions of this Agreement. In such cases, the part deemed invalid or unenforceable shall be construed in a manner consistent with applicable law to reflect, as closely as possible, the original intent of the parties.</li>
                <li>Any waiver of any right under this Agreement is only effective if it is in writing and it applies only to the party to whom the waiver is addressed and to the circumstances for which it is given. No failure to exercise or delay in exercising any right or remedy provided under this Agreement or by law constitutes a waiver of such right or remedy nor shall it prevent any future exercise or enforcement of such right or remedy.</li>
                <li>Unless otherwise expressly stated, nothing in this Agreement shall create or confer any rights or any other benefits to third parties.</li>
                <li>This Agreement contains the entire agreement between you and us relating to its subject matter. You confirm that, in agreeing to accept this Agreement, you have not relied on any representation save insofar as the same has expressly been made a representation in this Agreement.</li>
                <li>This Agreement is personal to you and you may not assign any of your rights or obligations to any third party. Any assignment in violation of this provision shall be void and of no effect. We reserve the right to transfer, assign, and sublicense or pledge this Agreement, in whole or in part, to any person without notice and you will be deemed to consent to such assignment.</li>
                <li>In this Agreement and unless otherwise stated, “we”, “us” or “our” refers collectively to the Company and its subsidiaries, affiliates, directors, officers, employees, agents and contractors.</li>
            </ol>

            <hr />

            <h3>14 – Governing Law</h3>
            <ol type="i">
                <li>This Agreement and any dispute or claim arising out of or in connection with it shall be governed by the laws of England and Wales. The parties irrevocably agree that the courts of the United Kingdom shall have non-exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this agreement or our subject matter (including non-contractual disputes or claims).</li>
            </ol>
        </Container>
    );
}

export default TermsOfService;