import Amplify from 'aws-amplify'

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,

        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USERPOOLID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        /*
        cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
        },
        */

        oauth:
        {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URL,
            redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_URL,
            scope: [
                "phone",
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin",
            ],
            responseType: "code",
        },

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },

    API:
    {
        endpoints:
        [
            {
                name: 'Web Portal API',
                endpoint: process.env.REACT_APP_WEBPORTALAPI,
                region: process.env.REACT_APP_API_GW_REGION,
            },
        ]
    },
});