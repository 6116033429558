import React from 'react';
import
{
    useParams,
    useHistory,
}
from 'react-router-dom';
import update from 'immutability-helper';
// import { useCookies } from 'react-cookie';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import CouponCard from './CouponCard';
import CouponModal from './CouponModal';
import PortalAPI from '../PortalAPI';
import ScrollGuide from './ScrollGuide';


function CampaignGroup()
{
    let { campaign_group_id } = useParams();    
    const [campaignGroup, setCampaignGroup] = React.useState([]);
    const [campaignGroupLoaded, setCampaignGroupLoaded] = React.useState(false);
    const [campaignGroupAlert, setCampaignGroupAlert] = React.useState("");
    // const [, setCookie] = useCookies(['addSelectedCampaigns', 'addCampaignGroup', 'parentCampaignGroupId']);
    const [selectedCoupon, setSelectedCoupon] = React.useState({
        name: null,
        description: null,
        imageUrl: null,
        maxUserRedeem: null,
        terms: null,
        expireDate: null,
        redeemCount: null,
        isExpired: null
    });
    const [showCouponModal, setShowCouponModal] = React.useState(false);

    let history = useHistory();

    React.useEffect(() =>
    {
        PortalAPI.getCampaignGroup(campaign_group_id).then(function(response)
        {         
            setCampaignGroupLoaded(true);

            if(response.code === 200)
            {
                let campaignGroup = response.payload;

                campaignGroup.sort(function(a, b)
                {
                    if(a.priority !== b.priority) return(a.priority < b.priority) ? 1 : -1;
                    return(a.name < b.name) ? -1 : 1;
                });

                for(let campaign of campaignGroup)
                {
                    campaign.selected = false; // we will need this property later to keep a tally of all selected coupons
                }

                setCampaignGroup(campaignGroup);
            }
            else if(response.code === 404)
            {
                setCampaignGroupAlert("Campaign Group ID not found");
            }
            else
            {
                console.log('error!', response);
                // deal with errors

                setCampaignGroupAlert("An unknown error has occurred when trying to load a campaign group");
            }
        }).catch(function(err)
        {
            console.log('getCampaignGroup error:', err);
        });
    }, [campaign_group_id]);


    function handleAddCampaignGroupClick(campaignGroupId)
    {
        // setCookie('addCampaignGroup', campaignGroupId,
        // {
        //     path: '/',
        //     expires: new Date('2038-01-01'),
        // });

        history.push(`/wallet?addCampaignGroup=${campaignGroupId}`);
    }


    function handleAddSelectedCouponsClick(campaignGroupId)
    {
        let selectedCouponIds = [];

        for(let coupon of campaignGroup)
        {
            if(coupon.selected)
            {
                selectedCouponIds.push(coupon.campaignId);
            }
        }

        const selectedCouponsB64 = window.btoa(JSON.stringify(selectedCouponIds)); // base 64 encode so we can pass as url param

        // setCookie('addSelectedCampaigns', selectedCouponsB64,
        // {
        //     path: '/',
        //     expires: new Date('2038-01-01'),
        // });

        // setCookie('parentCampaignGroupId', campaignGroupId,
        // {
        //     path: '/',
        //     expires: new Date('2038-01-01'),
        // });

        history.push(`/wallet?addCampaigns=${selectedCouponsB64}&parentCampaignGroupId=${campaignGroupId}`);
    }


    function handleSelectCouponClick(coupon)
    {
        // find the matching coupon from the wallet so we can 'update' it
        const couponIndex = campaignGroup.findIndex(function(campaignGroupCoupon)
        {
            return(coupon.campaignId === campaignGroupCoupon.campaignId);
        });

        // create a new copy of the immutable campaignGroup with the relevant entry updated
        const newCampaignGroupData = update(campaignGroup, {[couponIndex] : {$toggle: ['selected']}});

        setCampaignGroup(newCampaignGroupData);
    }


    function getSelectedCouponCount()
    {
        const count = campaignGroup.filter((campaign) => campaign.selected === true).length;

        return(count);
    }


    function handleDetailsClick(coupon)
    {
        setShowCouponModal(true);
        setSelectedCoupon(coupon);
    }

    function handleModalHide()
    {
        setShowCouponModal(false);
    }

    const coupons = campaignGroup.map(function(coupon)
    {
        return(
            <Col key={coupon.campaignId} xl={4} lg={6} md={6} sm={12} className="mb-4">
                <div className="card-container h-100">
                    <div>
                        <div>
                            <CouponCard coupon={coupon} onDetailsClick={handleDetailsClick.bind(this, coupon)} allowSelection onSelectCouponClick={handleSelectCouponClick} showAgeCheck />
                        </div>
                    </div>                                               
                </div>
            </Col>
        );
    });

    return(
        <>
            <CouponModal coupon={selectedCoupon} show={showCouponModal} onHide={handleModalHide}/>
            <Container>
                {
                    campaignGroupAlert ?                    
                    <Alert variant="danger">{campaignGroupAlert}</Alert> :
                    <>
                        {
                            campaignGroupLoaded ?
                            <>
                                <p>A simple account registration is required if not already a member. This will allow you to claim future coupons and add them to your personal wallet.</p>
                                {
                                    getSelectedCouponCount() > 0 ?
                                    <Alert variant="light" className="d-flex align-items-center justify-content-between p-2 sticky">
                                        <span>You have selected {getSelectedCouponCount()} {getSelectedCouponCount() === 1 ? "Coupon." : "Coupons."} Click to add them to your wallet.</span>
                                        <Button variant="success" onClick={() => handleAddSelectedCouponsClick(campaign_group_id)}><FontAwesomeIcon icon={faPlusCircle}/> Add Selected Coupons to your Wallet</Button>
                                    </Alert> :
                                    <Alert variant="light" className="d-flex align-items-center justify-content-between p-2 sticky">
                                        <span>Select the coupons below you would like to add to your wallet, or add all of them.</span>
                                        <Button variant="primary" onClick={handleAddCampaignGroupClick.bind(this, campaign_group_id)}><FontAwesomeIcon icon={faPlusCircle}/> Add All Coupons to your Wallet</Button>
                                    </Alert>
                                }
                            </> :
                            <p>Loading Campaign Group Details...</p>
                        }
                        <Row>
                            {coupons}
                        </Row>
                    </>
                }
            </Container>

            <ScrollGuide />
        </>
    );
}

export default CampaignGroup;