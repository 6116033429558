import React from 'react';

import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import campaignImageCropped from '../img/iPhone_campaign_cropped.png';
import walletImage from '../img/iPhone_coupon_wallet.png';
import locationImage from '../img/iPhone_redemption_locations.png';
import redeemImage from '../img/iPhone_coupon_redeem.png';

function LandingPage()
{
    const history = useHistory();

    function onSignupClick()
    {
        history.push('/wallet');
    }

    return(
        <Container className="landing-page" fluid>
            <Row className="wrapper">
                <Col>
                    <Container>
                        <Row>
                            <Col md={7} sm={12}>
                                <h1>Smart Coupons save you money</h1>
                                <h3>
                                    Smart Coupons give you great discounts and coupons for your favourite brands at stores local to you.
                                    They&apos;re simple to use and save you money... great!
                                </h3>
                                <Button size="lg" className="mt-3 mb-5" onClick={onSignupClick}>Sign Up / In</Button>
                            </Col>
                            <Col md={5} sm={12}>
                                <Image className="campaign_image_cropped mx-auto d-block" src={campaignImageCropped} />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row style={{backgroundColor: '#f95312', color: 'white'}} className="wrapper">
                <Col>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <h2>How does it work?</h2>
                                <p>It&apos;s easy - follow these 3 simple steps to start saving money on the brands you love.</p>
                            </Col>
                        </Row>
                        <Row className="instructions-row">
                            <Col md={4}>
                                <Image src={walletImage} className="mx-auto d-block" />
                                <p>You&apos;ll find compatible coupons in places like stores, bus stops and on social media! Just scan the QR code or click the link using your phone and add the coupon to your wallet.</p>
                            </Col>
                            <Col md={4}>
                                <Image src={locationImage} className="mx-auto d-block" />
                                <p>You&apos;ll find all participating stores here. Just click the coupon details, find your closest participating store and pay them a visit.</p>
                            </Col>
                            <Col md={4}>
                                <Image src={redeemImage} className="mx-auto d-block" />
                                <p>At the checkout, just show your Wallet barcode to the staff. They&apos;ll scan it on the till with the qualifying products and any valid coupon discounts will be instantly applied. Redeemed coupons will then be removed from your wallet.</p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default LandingPage;