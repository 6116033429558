import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { Auth } from 'aws-amplify';

SignUp.propTypes =
{
    onSignIn: PropTypes.func.isRequired,
    onFormSwitch: PropTypes.func.isRequired,
};

function SignUp(props) 
{
    const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [password, setPassword] = useState('L.$EL!Q7z]%UfkK.');
    const [marketingPermission, setMarketingPermission] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    function onSignUpClick(event)
    {
        async function cognitoSignUp()
        {
            try 
            {
                await Auth.signUp(
                {
                    username: email,
                    password: password,
                    attributes:
                    {
                        email: email,
                        'custom:marketing_permission': marketingPermission ? "1" : "0", // even though the cognito field is a Number, it still expects input in string format
                    },                
                });

                await cognitoSignIn();
            }
            catch(error)
            {
                setIsFormSubmitting(false);
                setErrorText(error.message);
                window.scrollTo(0, 0);
            }
        }

        async function cognitoSignIn()
        {
            try 
            {
                const user = await Auth.signIn(email, password);

                props.onSignIn(user);
            }
            catch(error)
            {
                setErrorText(error.message);
            }
        }

        if(event)
        {
            event.preventDefault();
        }

        setIsFormSubmitting(true);

        cognitoSignUp();
    }

    return(
        <>
          {
              errorText && <Alert variant="danger">{errorText}</Alert>
          }
          <Card className="mb-2">
            <Card.Header className="smartcoupons-signup text-white">Create new Smart Coupons account</Card.Header>
            <Card.Body>
              <Form onSubmit={onSignUpClick}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control className="pt-4 pb-4 pl-3 pr-3" type="email" autoComplete="username" placeholder="Enter your email address" value={email} onChange={e => setEmail(e.target.value)}/>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" style={{display: 'none'}}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control className="pt-4 pb-4 pl-3 pr-3" type="text" autoComplete="new-password" placeholder="Enter your password" value={password} onChange={e => setPassword(e.target.value)}/>
                    <Form.Text className="text-muted">Password must be at least 8 characters including a number, a lowercase letter, an uppercase letter and a symbol</Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicMarketingPermission" style={{display: 'none'}}>
                    <Form.Check type="checkbox" label="Please keep me up to date with the latest offers" checked={marketingPermission} onChange={e => setMarketingPermission(e.target.checked)}/>
                </Form.Group>
                <Button disabled={isFormSubmitting} className="btn-block smartcoupons-signup" size="lg" type="submit">
                    {
                        isFormSubmitting ?
                        <Spinner animation="border" size="sm"/> :
                        "Activate Coupon"
                    }
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Body className="text-center">
              Already have a Smart Coupons Account? <button type="button" className="link-button" onClick={(event) => props.onFormSwitch('signin', event)}>Sign In</button>
            </Card.Body>
          </Card>
        </>);
}

export default SignUp;