import React from 'react';
import Card from 'react-bootstrap/Card';

import StoreMap from './StoreMap';

function ParticipatingStoresCard()
{
    return(
        <Card className="h-100 smartcoupons-card">
            <Card.Header className="text-white">Participating Stores</Card.Header>
            <StoreMap />
            <Card.Footer>
                Drag flag to set location and find stores in that area. Please ensure Location Services are enabled.
            </Card.Footer>
        </Card>
    );
}

export default ParticipatingStoresCard;