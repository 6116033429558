import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSignOutAlt, faStar, faUser, /*faUserCircle,*/ faWallet } from '@fortawesome/free-solid-svg-icons';

import { Auth, Hub } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import logo from '../SmartCouponsLogo.png';

InTouchNavbar.propTypes =
{
    hideNavbarControls: PropTypes.bool,
};

function InTouchNavbar(props)
{
    return(
        <Navbar className="smartcoupons-navbar" variant="dark" fixed="top" expand="lg">
            <Container>
                <Navbar.Brand className="p-0" href="/">
                    <img src={logo}
                        height="50"
                        className="d-inline-block align-middle"
                        alt="Smart Coupons logo" />
                </Navbar.Brand>
                {
                    !props.hideNavbarControls &&
                    <>
                        <Navbar.Toggle />
                        <Navbar.Collapse>
                            <LoginControl />
                        </Navbar.Collapse>
                    </>
                }
            </Container>
        </Navbar>
    );
}


function LoginControl()
{
    const [currentUser, setCurrentUser] = useState(null);
    const [loggedIn, setLoggedIn] = useState(null);

    let history = useHistory();

    useEffect(function()
    {
        async function getUser()
        {
            try
            {
                const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

                let username = currentAuthenticatedUser.attributes.email;

                // this won't get updated until the page is refreshed, or user moves to another page
                if(currentAuthenticatedUser.attributes.given_name && currentAuthenticatedUser.attributes.family_name)
                {
                    username = `${currentAuthenticatedUser.attributes.given_name} ${currentAuthenticatedUser.attributes.family_name}`;
                }

                setCurrentUser(username);
                setLoggedIn(true);
            }
            catch(error)
            {
                // if no user is currently authenticated we hit this point
                setCurrentUser(null);
                setLoggedIn(false);
            }
        }

        const listener = function(data)
        {
            switch (data.payload.event)
            {
                case 'signIn':
                    setLoggedIn(true);
                    break;
                case 'signOut':
                    setLoggedIn(false);
                    break;
                default:
                    break;
            }
        }

        Hub.listen('auth', listener);

        getUser(); // call the async function

        return(function cleanup()
        {
            Hub.remove('auth', listener);
        });

    }, [currentUser, loggedIn]);


    function signIn()
    {
        history.push('/wallet'); // this will trigger the sign in as the wallet page is protected
    }


    function signOut()
    {
        Auth.signOut()
        .then(function()
        {
            history.push('/');
        });
    }
    
    return(
        <>
            {
                loggedIn === false ?
                <LoggedOut onSignInClick={signIn}/> :
                <LoggedIn currentUser={currentUser} onSignOutClick={signOut}/>
            }
        </>
    );
}


LoggedIn.propTypes =
{
    currentUser: PropTypes.string,
    onSignOutClick: PropTypes.func.isRequired,
};

function LoggedIn(props)
{    
    return(
        <>
            {/* this is the collapsed menu that we will display */}
            <Nav className="d-lg-none justify-content-end" activeKey={window.location.pathname}>
                <Navbar.Text className="mt-2 font-weight-bold"><FontAwesomeIcon icon={faUser} /> Signed in as {props.currentUser}</Navbar.Text>
                <Nav.Link href="/wallet" className="mb-1 font-weight-normal"><FontAwesomeIcon icon={faWallet} /> My Coupon Wallet</Nav.Link>
                <Nav.Link href="/latest_offers" className="mb-1 font-weight-normal"><FontAwesomeIcon icon={faStar} /> Latest Offers</Nav.Link>
                {/* <Nav.Link href="/account" className="mb-1 font-weight-normal"><FontAwesomeIcon icon={faUserCircle} /> My Account</Nav.Link> */}
                <Button className="smartcoupons-signin" variant="warning" onClick={props.onSignOutClick}><FontAwesomeIcon icon={faSignOutAlt} /> Sign Out</Button>
            </Nav>

            {/* This is the non-collapsed version */}
            <Nav className="d-none d-lg-flex" activeKey={window.location.pathname}>
                <Nav.Link href="/wallet">My Coupon Wallet</Nav.Link>
                <Nav.Link href="/latest_offers">Latest Offers</Nav.Link>
            </Nav>

            <Nav className="d-none d-lg-block ml-auto" activeKey={window.location.pathname}>
                <Navbar.Text className="d-inline-flex">Signed in as&nbsp;</Navbar.Text>
                <DropdownButton className="d-inline-flex smartcoupons-signin" variant="warning" title={
                    <>
                        <FontAwesomeIcon icon={faUser} /> {props.currentUser}&nbsp;
                    </>
                } alignRight>
                    {/* <Dropdown.Item href="/account"><FontAwesomeIcon icon={faUserCircle} /> My Account</Dropdown.Item>
                    <Dropdown.Divider /> */}
                    <Dropdown.Item><Button className="smartcoupons-signin" variant="warning" onClick={props.onSignOutClick} block><FontAwesomeIcon icon={faSignOutAlt} /> Sign Out</Button></Dropdown.Item>
                </DropdownButton>
            </Nav>
        </>
    );
}


LoggedOut.propTypes =
{
    onSignInClick: PropTypes.func.isRequired,
};

function LoggedOut(props)
{
    return(
        <>
            <Navbar.Text className="ml-auto">
                Already a member? Click here to
            </Navbar.Text>
            <Button className="ml-2 smartcoupons-signin" type="button" variant="warning" onClick={props.onSignInClick}><FontAwesomeIcon icon={faSignInAlt} /> Sign In</Button>
        </>
    );
}

export default InTouchNavbar;