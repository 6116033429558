import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

import '../AWS';
import { Auth } from 'aws-amplify';

import PortalAPI from '../PortalAPI';

import Wallet from './Wallet';


import { useLocation, useHistory } from 'react-router-dom';
// import { useCookies } from 'react-cookie';

function WalletAgeGate(props)
{
    const [ageConfirmed, setAgeConfirmed] = React.useState(null);
    const [requiredAge, setRequiredAge] = React.useState();
    const [ageOverridable, setAgeOverridable] = React.useState(true);
    const [acceptedCampaigns, setAcceptedCampaigns] = React.useState([]);
    const [rejectedCampaigns, setRejectedCampaigns] = React.useState([]);
    const [addCampaignsFiltered, setAddCampaignsFiltered] = React.useState(); // this is a list of campaigns to add - it can change based on actions on this page
    const [addCampaignGroupFiltered, setAddCampaignGroupFiltered] = React.useState(); // this is a list of campaigns to add - it can change based on actions on this page
    const [error, setError] = React.useState();

    // const [cookies, , ] = useCookies(['addSelectedCampaigns', 'addCampaignGroup']);

    // get query string argument - one or none of these will have been set
    let query = useQuery();
    let history = useHistory();

    let addCampaignIdToWallet = query.get('add'); // add specific campaign
    let addCampaignGroupIdToWallet = query.get('addCampaignGroup');//      || cookies['addCampaignGroup']; // add all campaigns from a campaign group
    let addCampaignsToWallet = query.get('addCampaigns');//          || cookies['addSelectedCampaigns']; // add selection of campaigns
    let parentCampaignGroupId = query.get('parentCampaignGroupId');// || cookies['parentCampaignGroupId']; // if we add a selection of campaigns from a campaign group, pass the group id through too

    React.useEffect(() =>
    {
        async function getUserAndCampaigns()
        {
            if(props.user != null)
            {
                const birthDate = props.user.attributes.birthdate || null;
                const confirmedAge = parseInt(props.user.attributes["custom:confirmed_age"]) || null;

                let ageInYears = 0;
                
                if(birthDate)
                {
                    ageInYears = calculateAge(new Date(birthDate));

                    setAgeOverridable(false); // if we have specifically recorded a date of birth, we can't allow the user to override the age gate
                }

                if(confirmedAge == null && ageInYears > 0)
                {
                    await setCognitoConfirmedAge(props.user, ageInYears);
                }
                else if(confirmedAge != null && confirmedAge != ageInYears && ageInYears > 0)
                {
                    await setCognitoConfirmedAge(props.user, ageInYears);
                }
                else if(confirmedAge != null && ageInYears == 0)
                {
                    ageInYears = confirmedAge;
                }

                if(addCampaignIdToWallet != null)
                {
                    try
                    {
                        const response = await PortalAPI.getCampaign(addCampaignIdToWallet);

                        if(response.code == 200)
                        {
                            const campaign = response.payload;

                            setRequiredAge(campaign.ageCheck);

                            if(campaign.ageCheck <= ageInYears)
                            {
                                setAcceptedCampaigns([campaign]);
                                setAgeConfirmed(true);
                            }
                            else
                            {
                                setRejectedCampaigns([campaign]);
                                setAgeConfirmed(false);
                            }
                        }
                        else
                        {
                            setError(response.message);
                        }
                    }
                    catch(err)
                    {
                        setError(err);
                    }
                }
                else if(addCampaignGroupIdToWallet != null)
                {
                    try
                    {
                        const response = await PortalAPI.getCampaignGroup(addCampaignGroupIdToWallet);

                        const campaignGroup = response.payload;

                        checkCampaignAges(ageInYears, campaignGroup);

                        // clear out cookies that may or may not be present
                        // removeCookie('addCampaignGroup');
                    }
                    catch(err)
                    {
                        setError(err);
                    }
                }
                else if(addCampaignsToWallet != null)
                {                                    
                    try
                    {
                        const response = await PortalAPI.getCampaigns(addCampaignsToWallet);

                        if(response.code == 200)
                        {
                            const campaigns = response.payload;

                            checkCampaignAges(ageInYears, campaigns);

                            // clear out cookies that may or may not be present
                            // removeCookie('addSelectedCampaigns');
                            // removeCookie('parentCampaignGroupId');
                        }
                        else
                        {
                            setError(response.message);
                        }
                    }
                    catch(err)
                    {
                        setError(err);
                    }
                }
                else // if no campaigns have been added, we can just skip this check and load the wrapped page
                {
                    setAgeConfirmed(true);
                }
            }
        }

        setAddCampaignsFiltered(addCampaignsToWallet);
        setAddCampaignGroupFiltered(addCampaignGroupIdToWallet);

        getUserAndCampaigns();
    }, [props.user, addCampaignIdToWallet, addCampaignsToWallet, addCampaignGroupIdToWallet]);


    async function setCognitoConfirmedAge(user, confirmedAge)
    {
        try
        {
            await Auth.updateUserAttributes(user,
            {
                'custom:confirmed_age': confirmedAge.toString(),
            });
        }
        catch(err)
        {
            console.log('error updating cognito user attributes:', err);
        }        
    }


    function checkCampaignAges(ageInYears, campaigns)
    {
        let maxRequiredAge = 0;
        let acceptedCampaigns = [];
        let rejectedCampaigns = [];

        for(const campaign of campaigns)
        {
            if(campaign.ageCheck > maxRequiredAge)
            {
                maxRequiredAge = campaign.ageCheck;
            }

            if(ageInYears < campaign.ageCheck)
            {
                rejectedCampaigns.push(campaign);
            }
            else
            {
                acceptedCampaigns.push(campaign);
            }
        }

        setRequiredAge(maxRequiredAge);

        if(maxRequiredAge <= ageInYears)
        {
            setAgeConfirmed(true);
        }
        else
        {
            setAgeConfirmed(false);
        }

        setAcceptedCampaigns(acceptedCampaigns);
        setRejectedCampaigns(rejectedCampaigns);
    }

    function calculateAge(birthdate) // birthday is a Date
    {
        var ageDiffMs = Date.now() - new Date(birthdate);
        var ageDate = new Date(ageDiffMs); // milliseconds from epoch

        return(Math.abs(ageDate.getUTCFullYear() - 1970));
    }
        

    function handleAgeConfirm(user, ageConfirmed)
    {
        setCognitoConfirmedAge(user, ageConfirmed).then(function()
        {
            setAgeConfirmed(true);
        });
    }


    // if the user confirms they are NOT old enough - in that case we want to just add the non age limited coupons
    function handleAgeRejected()
    {
        const couponIds = [];

        for(let coupon of acceptedCampaigns)
        {
            couponIds.push(coupon.campaignId);
        }

        const couponsB64 = window.btoa(JSON.stringify(couponIds)); // base 64 encode

        setAddCampaignsFiltered(couponsB64);
        setAddCampaignGroupFiltered(null); // we need to make sure we don't pass through the campaign group id as well as the filtered list of campaigns

        setAgeConfirmed(true);
    }

    function handleBack()
    {
        history.goBack();
    }

    // we don't render until we have checked if we need to display the age warning to stop the 'flash' of the element
    if(ageConfirmed == null)
    {
        return(null);
    }

    const allowedCoupons = acceptedCampaigns.map(function(campaign)
    {
        return(
            <ListGroup.Item key={campaign.campaignId}>{campaign.name} - {campaign.description}</ListGroup.Item>
        );
    });

    const rejectedCoupons = rejectedCampaigns.map(function(campaign)
    {
        return(
            <ListGroup.Item key={campaign.campaignId}>{campaign.name} - {campaign.description} ({campaign.ageCheck})</ListGroup.Item>
        );
    });

    // setting age confirmed is what will pass the data through to the Wallet component and actually add coupons to the wallet
    if(ageConfirmed)
    {
        return(<Wallet user={props.user} addCampaignId={addCampaignIdToWallet} addCampaignGroupId={addCampaignGroupFiltered} addCampaigns={addCampaignsFiltered} parentCampaignGroupId={parentCampaignGroupId} />);
    }
    else
    {
        return(
            <Container>
                {
                    error != null &&
                    <Alert variant="danger">{error}</Alert>
                }

                <Alert variant="danger" className="agegate">
                    <h6 className="mb-0">
                        Some of the coupons you have selected require you to be {requiredAge} years old.
                    </h6>
                </Alert>

                {
                    ageOverridable &&
                    <Row className="d-flex align-items-center p-2">
                        <Col md="12" lg="7">Click here if you are over {requiredAge} to add all of the coupons to your wallet</Col>
                        <Col md="12" lg="5"><Button block variant="success" onClick={() => handleAgeConfirm(props.user, requiredAge)}><FontAwesomeIcon icon={faCheck}/> I confirm I am over {requiredAge}</Button></Col>
                    </Row>
                }

                {
                    allowedCoupons.length > 0 &&
                    <Row className="d-flex align-items-center p-2">
                        <Col md="12" lg="7">Click here if you are NOT over {requiredAge} to add the Non Age Restricted coupons to your wallet</Col>
                        <Col md="12" lg="5"><Button block variant="warning" onClick={() => handleAgeRejected()}><FontAwesomeIcon icon={faCheck}/> I am not over {requiredAge}</Button></Col>
                    </Row>
                }

                <Row className="d-flex align-items-center p-2">
                    <Col md="12" lg="7">Go back to Coupon selection</Col>
                    <Col md="12" lg="5"><Button block variant="danger" onClick={() => handleBack()}><FontAwesomeIcon icon={faArrowAltCircleLeft}/> Back</Button></Col>
                </Row>

                <Row className="mt-3">
                    <Col md={6}>
                        <ListGroup className="mb-3">
                            <ListGroup.Item variant="warning">Age Restricted Coupons</ListGroup.Item>
                            {rejectedCoupons}
                        </ListGroup>
                    </Col>

                    {
                        allowedCoupons.length > 0 &&
                        <Col md={6}>
                            <ListGroup>
                                <ListGroup.Item variant="success">Non Age Restricted Coupons</ListGroup.Item>
                                {allowedCoupons}
                            </ListGroup>
                            </Col>
                    }
                </Row>
            </Container>
        );
    }
}

function useQuery()
{
    return(new URLSearchParams(useLocation().search));
}

export default WalletAgeGate;