import React from 'react';
import
{
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.min.css';
// import './bootstrap.min.css';

import './in-touch.scss';

import InTouchNavbar from './components/InTouchNavbar';
// import Account from './components/Account';
import Campaign from './components/Campaign';
import CampaignGroup from './components/CampaignGroup';
import LandingPage from './components/LandingPage';
import Footer from './components/Footer';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import LatestOffers from './components/LatestOffers';
import WalletAgeGate from './components/WalletAgeGate';
import PortalAuth from './components/PortalAuth';
import StoreFinder from './components/StoreFinder';
import SaviCoupon from './components/SaviCoupon';

import './App.css';

// import backgroundImage from './img/iPhone_campaign.png';

function App()
{
    const [user, setUser] = React.useState(null);

    // TODO - do we need any kind of background etc? 
    var sectionStyle =
    {
        // backgroundImage: `url(${backgroundImage})`,
        // backgroundSize: '100%',
    };

    function handleUserChange(newUser)
    {
        setUser(newUser);
    }

    return(
        <div className="d-flex flex-column min-vh-100" style={sectionStyle}>
            <BrowserRouter>
                <Switch>
                    <Route path="/campaign/:campaign_id">
                        <InTouchNavbar hideNavbarControls />
                        <Campaign />
                    </Route>
                    <Route path="/campaign-group/:campaign_group_id">
                        <InTouchNavbar hideNavbarControls />
                        <CampaignGroup />
                    </Route>
                    <Route path="/latest_offers">
                        <InTouchNavbar />
                        <LatestOffers />
                    </Route>
                    <Route path="/wallet">
                        <InTouchNavbar />
                        <PortalAuth onUserChange={handleUserChange}>
                            <WalletAgeGate user={user} />
                        </PortalAuth>
                    </Route>
                    {/* <Route path="/account">
                        <InTouchNavbar />
                        <Account />
                    </Route> */}
                    <Route path="/terms_of_service">
                        <InTouchNavbar />
                        <TermsOfService />
                    </Route>
                    <Route path="/privacy_policy">
                        <InTouchNavbar />
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/store_finder">
                        <StoreFinder />
                    </Route>
                    <Route path="/galaxy/:pin">
                        <SaviCoupon />
                    </Route>
                    <Route path="/">
                        <InTouchNavbar />
                        <LandingPage />
                    </Route>
                </Switch>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;