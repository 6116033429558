import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

function ScrollGuide()
{
    const [displayScrollGuide, setDisplayScrollGuide] = React.useState(false);

    React.useEffect(function()
    {
        window.addEventListener('scroll', handleCampaignGroupScroll);

        displayScrollGuideIfNeeded();

        // Specify how to clean up after this effect:
        return function cleanup()
        {
            window.removeEventListener('scroll', handleCampaignGroupScroll);
        };
    });

    function handleCampaignGroupScroll()
    {
        displayScrollGuideIfNeeded();
    }


    function displayScrollGuideIfNeeded()
    {
        const maxScrollPos = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;
        const currentScrollPos = document.documentElement.scrollTop;
        
        if(currentScrollPos < maxScrollPos)
        {
            setDisplayScrollGuide(true);
        }
        else
        {
            setDisplayScrollGuide(false);
        }
    }


    if(displayScrollGuide)
    {
        return(
            <Container className="fixed-bottom">
                <Row className="text-center justify-content-md-center">
                    <Col className="col-md-auto">
                        <Alert variant="success" className="mb-0 border-0 pt-2 pb-1"><div className="d-inline-flex align-items-center"><FontAwesomeIcon className="fa-2x" icon={faArrowAltCircleDown}/>&nbsp;&nbsp;&nbsp;<span>Scroll down to see more!</span>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon className="fa-2x" icon={faArrowAltCircleDown}/></div></Alert>
                    </Col>
                </Row>
            </Container>);
    }
    else
    {
        return(null);
    }
}

export default ScrollGuide;