import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { Auth } from 'aws-amplify';

ForgotPassword.propTypes =
{
    onFormSwitch: PropTypes.func.isRequired,
    onEmailChange: PropTypes.func.isRequired,
};

function ForgotPassword(props)
{
    const [email, setEmail] = useState("");
    const [errorText, setErrorText] = useState("");
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    function onForgotPasswordClick(event)
    {
        async function cognitoForgotPassword()
        {
            try 
            {
                await Auth.forgotPassword(email);

                props.onFormSwitch('forgotpasswordsubmit');
            }
            catch(error)
            {
                setIsFormSubmitting(false);

                setErrorText(error.message);
            }
        }

        if(event)
        {
            event.preventDefault();
        }

        setIsFormSubmitting(true);

        cognitoForgotPassword();
    }

    function handleOnChange(email)
    {
        setEmail(email);
        props.onEmailChange(email);
    }

    return(
      <>
        {
            errorText && <Alert variant="danger">{errorText}</Alert>
        }
        <Card className="mb-2">
          <Card.Header className="smartcoupons-forgottenpassword text-white">Reset your Smart Coupons password</Card.Header>
          <Card.Body>
            <Form onSubmit={onForgotPasswordClick}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control className="pt-4 pb-4 pl-3 pr-3" type="email" autoComplete="username" placeholder="Enter your email address" value={email} onChange={e => handleOnChange(e.target.value)}/>
              </Form.Group>
              <Button className="btn-block smartcoupons-forgottenpassword" disabled={isFormSubmitting} size="lg" type="submit">
                {
                  isFormSubmitting ?
                  <Spinner animation="border" size="sm"/> :
                  "Send Code"
                }
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Body className="text-center">
            <button type="button" className="link-button" onClick={(event) => props.onFormSwitch('signin', event)}>Back to Sign In</button>
          </Card.Body>
        </Card>
        <br/>
      </>
    );
}

export default ForgotPassword;