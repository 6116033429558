import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import intouchLogo from '../in-touch-linear.png';
import saviLogo from '../savi-logo.png';

function Footer()
{
    return(
        <footer className="mt-auto footer">
            <Container fluid className="bg-light">
                <Container>
                    <Row className="pt-2 pb-2">
                        <Col sm={6}>
                            {/* © In-Touch Group {new Date().getFullYear()} */}
                            Powered by <img src={intouchLogo} alt="In Touch" /> and <img src={saviLogo} alt="Savi" style={{height: '16px'}} />
                        </Col>
                        <Col sm={6} className="text-right">
                            <a href="/terms_of_service">Terms of Service</a> | <a href="/privacy_policy">Privacy Policy</a>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </footer>
    );
}

export default Footer;