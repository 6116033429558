import React from 'react';
import PropTypes from 'prop-types';

import Card from 'react-bootstrap/Card';

CouponModalCard.propTypes =
{
    coupon: PropTypes.object.isRequired,
};

function CouponModalCard(props)
{
    return(
        <Card className="h-100 smartcoupons-card">
            <Card.Header className="text-white">Details</Card.Header>
            <Card.Img variant="top" src={props.coupon.imageUrl} className="mx-auto"/>
            <Card.Body className="text-justify">
                <Card.Text>{props.coupon.description}</Card.Text>
                {
                    props.coupon.redeemCount &&
                    <Card.Text>Redeemed: {props.coupon.redeemCount} of {props.coupon.maxUserRedeem} available coupons</Card.Text>
                }
                <Card.Text>Expires: {convertTimestampToHuman(props.coupon.expireDate)}</Card.Text>
                <Card.Subtitle className="mt-2 text-muted">Terms and Conditions</Card.Subtitle>
                <Card.Text>{props.coupon.terms}</Card.Text>
            </Card.Body>
        </Card>
    );
}


function convertTimestampToHuman(timestamp)
{
    var a = new Date(timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = ('00' + a.getHours()).substr(-2, 2); // zero pad
    var min = ('00' + a.getMinutes()).substr(-2, 2);
    var sec = ('00' + a.getSeconds()).substr(-2, 2);
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;

    return(time);
}
export default CouponModalCard;