import React from 'react';
import PropTypes from 'prop-types';

import Card from 'react-bootstrap/Card';

import Barcode from 'react-barcode';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';


WalletBarcode.propTypes =
{
    user: PropTypes.object.isRequired,
    hidePrintButton: PropTypes.bool,
    onPrintClick: PropTypes.func,
};


function WalletBarcode(props)
{
    const [userShortId, setUserShortId] = React.useState();

    React.useEffect(function()
    {
        // convert to hex
        let userLinkIdHex = parseInt(props.user.attributes['custom:user_link_id']).toString(16);

        // zero pad to 8 digits
        let userLinkIdHexPadded = userLinkIdHex.padStart(8, 0);

        setUserShortId(`ITW${userLinkIdHexPadded}`);
    }, [props.user]);

    return(
        <Card className="walletBarcode border-0">
            <Card.Body className="text-center">
                <>
                    {
                        props.hidePrintButton ? '' :
                        <FontAwesomeIcon className="barcodePrintButton" icon={faPrint} onClick={props.onPrintClick}/>
                    }
                    {
                        userShortId &&
                        <Barcode format="CODE128" width={1.5} displayValue={false} value={userShortId} />
                    }
                </>
            </Card.Body>
            <Card.Footer className="text-center">Scan in-store to apply valid coupons</Card.Footer>
        </Card>
    );
}

export default WalletBarcode;