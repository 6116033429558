import React from 'react';
import
{
    useParams,
    useHistory,
}
from 'react-router-dom';

import { useCookies } from 'react-cookie';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import CouponModal from './CouponModal';
import PortalAPI from '../PortalAPI';


function Campaign()
{
    let { campaign_id } = useParams();    
    const [campaign, setCampaign] = React.useState(
    {
        campaignId: null,
        campaignName: '',
        isExpired: false,
    });
    const [campaignLoaded, setCampaignLoaded] = React.useState(false);
    const [campaignAlert, setCampaignAlert] = React.useState("");
    const [showCouponModal, setShowCouponModal] = React.useState(false);
    const [, setCookie] = useCookies(['addCampaign']);

    let history = useHistory();

    React.useEffect(() =>
    {
        PortalAPI.getCampaign(campaign_id).then(function(response)
        {
            setCampaignLoaded(true);

            if(response.code === 200)
            {
                setCampaign(response.payload);
            }
            else if(response.code === 404)
            {
                setCampaignAlert("Campaign ID not found");
            }
            else
            {
                console.log('error!', response);
                // deal with errors

                setCampaignAlert("An unknown error has occurred when trying to load a campaign");
            }
        }).catch(function(err)
        {
            console.log('getCampaign error:', err);
        });
    }, [campaign_id]);


    function handleAddCouponClick(campaignId)
    {
        setCookie('addCampaign', campaignId,
        {
            path: '/',
            expires: new Date('2038-01-01'),
        });

        history.push(`/wallet?add=${campaignId}`);
    }


    function handleDetailsClick()
    {
        setShowCouponModal(true);
    }


    function handleModalHide()
    {
        setShowCouponModal(false);
    }

    return(
        <>
            <CouponModal coupon={campaign} show={showCouponModal} onHide={handleModalHide}/>
            <Container>
                {
                    campaignAlert ?                    
                    <Alert variant="danger">{campaignAlert}</Alert> :

                    <Row>
                        <Col lg={12}>
                        {
                            campaignLoaded ?
                            <>
                                <h1>{campaign.name} {campaign.isExpired && <span className="text-danger"> - CAMPAIGN EXPIRED</span>}</h1>
                                <p>{campaign.description}</p>
                                <p>
                                    <Button variant="primary" disabled={campaign.isExpired} onClick={handleAddCouponClick.bind(this, campaign.campaignId)}><FontAwesomeIcon icon={faPlusCircle}/> Add Coupon to your Wallet</Button>
                                    &nbsp;
                                    <Button variant="primary" onClick={handleDetailsClick}><FontAwesomeIcon icon={faInfoCircle}/> Details and Store Finder</Button>
                                </p>
                                <p>A simple account registration is required. This will allow you to claim future coupons and add them to your personal wallet.</p>
                                <p>
                                    <Image src={campaign.marketingImageUrl} fluid className="mx-auto d-block" style={{maxWidth: '80%'}}/>
                                </p>
                                <p>{campaign.terms}</p>
                            </> :
                            <p>Loading Campaign Details...</p>
                        }
                        </Col>                    
                    </Row>
                }
            </Container> 
        </>
    );
}

export default Campaign;