import React from 'react';

import StoreMap from './StoreMap';

function StoreFinder()
{
    return(
        <React.Fragment>
            <StoreMap height='800px'/>
            <p>Drag flag to set location and find stores in that area. Please ensure Location Services are enabled.</p>
            <p>Stores within approximately 10 miles of the flag will be displayed.</p>
        </React.Fragment>
    );
}

export default StoreFinder;