import React from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import '../AWS';

import CouponModal from './CouponModal';
import CouponCard from './CouponCard';
import NoCoupons from './NoCoupons';

import PortalAPI from '../PortalAPI';

WalletContents.propTypes =
{
    cognitoId: PropTypes.string.isRequired,
};

function WalletContents(props)
{
    const [selectedCoupon, setSelectedCoupon] = React.useState({
        name: null,
        description: null,
        imageUrl: null,
        maxUserRedeem: null,
        terms: null,
        expireDate: null,
        redeemCount: null,
        isExpired: null
    });
    const [walletData, setWalletData] = React.useState([]);
    const [isWalletLoaded, setIsWalletLoaded] = React.useState(false);
    const [showCouponModal, setShowCouponModal] = React.useState(false);

    React.useEffect(function()
    {
        async function loadWalletContents(cognitoId)
        {
            try
            {
                const response = await PortalAPI.getWallet(cognitoId);
                
                if(response.code === 200)
                {
                    response.payload.coupons.forEach(function(coupon)
                    {
                        if(coupon.redeemCount < coupon.maxUserRedeem)
                        {
                            coupon.isRedeemed = false;
                        }
                        else
                        {
                            coupon.isRedeemed = true;
                        }
                    });

                    // sort the coupons into sensible order (first priority, then alphabetically, then redeemed and finally expired coupons)
                    response.payload.coupons.sort(function(a, b)
                    {
                        if(a.isExpired !== b.isExpired) return(a.isExpired < b.isExpired) ? -1 : 1;
                        if(a.isRedeemed !== b.isRedeemed) return(a.isRedeemed < b.isRedeemed) ? -1 : 1;
                        if(a.priority !== b.priority) return(a.priority < b.priority) ? 1 : -1;
                        return(a.name < b.name) ? -1 : 1;
                    });

                    let walletCoupons = [];

                    for(let coupon of response.payload.coupons)
                    {
                        coupon.qrCodeValue = `IT|C|${cognitoId}|${coupon.campaignId}`;

                        walletCoupons.push(coupon);
                    }

                    setWalletData(walletCoupons);
                }

                setIsWalletLoaded(true);
            }
            catch(err)
            {
                console.log('get wallet error:', err);
            }
        }

        loadWalletContents(props.cognitoId);
    }, [props.cognitoId]);

    function handleModalHide()
    {
        setShowCouponModal(false);
    }

        
    function handleDetailsClick(coupon)
    {
        setShowCouponModal(true);
        setSelectedCoupon(coupon);
    }


    const wallet = walletData.map(function(coupon)
    {
        return(
            <Col key={coupon.campaignId} xl={4} lg={6} md={6} sm={12} className="mb-4">
                <div className="card-container h-100">
                    <div className="card-flip">
                        <div className="front">
                            <CouponCard coupon={coupon} onDetailsClick={handleDetailsClick.bind(this, coupon)} />
                        </div>
                    </div>
                </div>
            </Col>
        );
    });

    return(
        <>
            <CouponModal coupon={selectedCoupon} show={showCouponModal} onHide={handleModalHide}/>
            {
                isWalletLoaded ?
                    walletData.length > 0 ?
                    <>
                        <Row>
                            <Col>
                                <Alert variant="info"><h6 className="mb-0">Click <span className="font-weight-bold">Details</span> on a coupon to see participating stores and offer conditions.</h6></Alert>
                            </Col>
                        </Row>
                        <Row>
                            {wallet}
                        </Row>
                    </> :
                    <NoCoupons message="There are currently no coupons in your wallet" /> :
                <Row><Col>Loading Wallet...</Col></Row>
            }
        </>                
    );
}

export default WalletContents;

