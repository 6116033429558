import React from 'react';
import PropTypes from 'prop-types';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';


CouponCard.propTypes =
{
    coupon: PropTypes.object.isRequired,
    allowSelection: PropTypes.bool,
    showAgeCheck: PropTypes.bool,
    onDetailsClick: PropTypes.func.isRequired,
    onSelectCouponClick: PropTypes.func,
};

function CouponCard(props)
{
    return(
        <Card className="h-100 smartcoupons-card">
            <Card.Header className="text-white d-flex justify-content-between">
                <div>{props.coupon.name}</div>
                {
                    (props.showAgeCheck && props.coupon.ageCheck) &&
                    <div><Badge pill>Must be {props.coupon.ageCheck}</Badge></div>
                }
            </Card.Header>
            <div className="coupon-image-container">
                <Card.Img src={props.coupon.imageUrl} className={"coupon-image mx-auto"}/>
                {
                    props.coupon.isExpired ?
                    <h1 className="centered font-weight-bolder text-white">EXPIRED</h1> :
                    props.coupon.isRedeemed ?
                    <h1 className="centered font-weight-bolder text-white">REDEEMED</h1> : ""
                }
            </div>
            <Card.Body className="text-justify">
                <Card.Text>{props.coupon.description}</Card.Text>
            </Card.Body>
            <Card.Footer>
                {
                    // we only display the Select button if the allowSelection prop has been set. Then we expect the onSelectCouponClick function prop to also be set
                    props.allowSelection &&
                    (
                        <>
                            {
                                props.coupon.selected ?
                                <Button variant="success" onClick={() => props.onSelectCouponClick(props.coupon)}><FontAwesomeIcon icon={faCheckCircle}/> Selected</Button> :
                                <Button variant="primary" onClick={() => props.onSelectCouponClick(props.coupon)}><FontAwesomeIcon icon={faCircle}/> Select</Button>
                            }
                            &nbsp;
                        </>
                    )
                }
                <Button variant="primary" onClick={props.onDetailsClick}><FontAwesomeIcon icon={faInfoCircle}/> Details</Button>
            </Card.Footer>
        </Card>
    );
}
export default CouponCard;