import React from 'react';
import PropTypes from 'prop-types';

import { useCookies } from 'react-cookie';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import '../AWS';

import PortalAPI from '../PortalAPI';

import WalletBarcode from './WalletBarcode';
import WalletContents from './WalletContents';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

Wallet.propTypes =
{
    user: PropTypes.object,
    addCampaignId: PropTypes.string,
    addCampaigns: PropTypes.string,
    addCampaignGroupId: PropTypes.string,
    parentCampaignGroupId: PropTypes.string,
};

function Wallet(props)
{
    const [cookies, , removeCookie] = useCookies(['addCampaign']);

    const [cognitoId, setCognitoId] = React.useState();
    // const [useCookies, setCookies] =

    const [walletAlert, setWalletAlert] = React.useState(null);
    const [walletAlertError, setWalletAlertError] = React.useState(true); // we assume the alert is an error by default, as most possibilities are errors
    const [printMode, setPrintMode] = React.useState(false);

    const addCampaignId = props.addCampaignId || cookies.addCampaign || null;

    React.useEffect(function()
    {
        async function updateWallet()
        {            
            if(props.user != null)
            {
                const region = props.user.pool.userPoolId.substring(0, props.user.pool.userPoolId.indexOf('_')); // strip the region of the userpool out from the userpoolid
                const cognitoId = `${region}:${props.user.attributes.sub}`;

                if(addCampaignId != null)
                {
                    try
                    {
                        const response = await PortalAPI.addCampaignToWallet(cognitoId, addCampaignId);

                        if(response.code === 200)
                        {
                            const couponName = response.payload.campaign.name;

                            setWalletAlertError(false);
                            setWalletAlert(`${couponName} coupon successfully added to your wallet`);
                        }
                        else // all other responses
                        {
                            if(response.code === 409)
                            {
                                const couponName = response.payload.campaign.name;

                                setWalletAlert(`${couponName} coupon has already been added to your wallet`);
                            }
                            else if(response.code === 404)
                            {
                                setWalletAlert("This coupon does not exist")
                            }
                            else if(response.code === 410)
                            {
                                const couponName = response.payload.campaign.name;

                                setWalletAlert(`Sorry - the ${couponName} coupon has expired or been removed`);
                            }
                            else
                            {
                                setWalletAlert("Error adding coupon to your wallet");
                            }
                        }

                        removeCookie('addCampaign');
                    }
                    catch(err)
                    {
                        setWalletAlert("Error adding coupon to your wallet");
                    }
                }
                else if(props.addCampaignGroupId != null)
                {
                    try
                    {
                        const response = await PortalAPI.addCampaignGroupToWallet(cognitoId, props.addCampaignGroupId);

                        if(response.code === 200)
                        {
                            const campaigns = response.payload;

                            let couponsAdded = 0;
                            let duplicateCoupons = 0;
                            let expiredCoupons = 0;

                            for(let campaign of campaigns)
                            {
                                if(campaign.code === 200)
                                {
                                    couponsAdded++;
                                }
                                else if(campaign.code === 409)
                                {
                                    duplicateCoupons++;
                                }
                                else if(campaign.code === 410)
                                {
                                    expiredCoupons++;
                                }
                            }

                            let resultString = "";

                            if(couponsAdded > 0)
                            {
                                resultString += `${couponsAdded} coupon(s) successfully added to your wallet`;
                            }

                            if(duplicateCoupons > 0)
                            {
                                if(resultString.length > 0)
                                {
                                    resultString += "; ";
                                }

                                resultString += `${duplicateCoupons} coupon(s) already added to your wallet`;
                            }

                            if(expiredCoupons > 0)
                            {
                                if(resultString.length > 0)
                                {
                                    resultString += "; ";
                                }

                                resultString += `${expiredCoupons} coupon(s) have expired`;
                            }

                            setWalletAlertError(false);
                            setWalletAlert(resultString);
                        }
                        else // all other responses
                        {
                            if(response.code === 404)
                            {
                                setWalletAlert("This coupon campaign group does not exist");
                            }
                            else
                            {
                                setWalletAlert("Error adding coupon to your wallet");
                            }
                        }
                    }
                    catch(err)
                    {
                        setWalletAlert("Error adding coupon group to your wallet");
                    }
                }
                else if(props.addCampaigns != null)
                {
                    // we need to decode from base64
                    let selectedCampaignIds = JSON.parse(window.atob(props.addCampaigns));

                    try
                    {
                        const response = await PortalAPI.addCampaignsToWallet(cognitoId, selectedCampaignIds, props.parentCampaignGroupId);

                        if(response.code === 200)
                        {
                            const campaigns = response.payload;

                            let couponsAdded = 0;
                            let duplicateCoupons = 0;
                            let expiredCoupons = 0;

                            for(let campaign of campaigns)
                            {
                                if(campaign.code === 200)
                                {
                                    couponsAdded++;
                                }
                                else if(campaign.code === 409)
                                {
                                    duplicateCoupons++;
                                }
                                else if(campaign.code === 410)
                                {
                                    expiredCoupons++;
                                }
                            }

                            let resultString = "";

                            if(couponsAdded > 0)
                            {
                                resultString += `${couponsAdded} coupon(s) successfully added to your wallet`;
                            }

                            if(duplicateCoupons > 0)
                            {
                                if(resultString.length > 0)
                                {
                                    resultString += "; ";
                                }

                                resultString += `${duplicateCoupons} coupon(s) already added to your wallet`;
                            }

                            if(expiredCoupons > 0)
                            {
                                if(resultString.length > 0)
                                {
                                    resultString += "; ";
                                }

                                resultString += `${expiredCoupons} coupon(s) have expired`;
                            }

                            setWalletAlertError(false);
                            setWalletAlert(resultString);
                        }
                        else // all other responses
                        {
                            setWalletAlert("Error adding selected coupons to your wallet");
                        }
                    }
                    catch(err)
                    {
                        setWalletAlert("Error adding selected coupons to your wallet");
                    }
                }

                setCognitoId(cognitoId); // now we have run any updates to the wallet that we needed, we can set the cognito id which will trigger the wallet to render
            }
        }

        updateWallet();
    }, [props.user, addCampaignId, props.addCampaignGroupId, props.addCampaigns, props.parentCampaignGroupId, removeCookie]);
    

    function handleOnPrintClick()
    {
        setPrintMode(true);
    }


    // render the page conditionally
    return(
            printMode ?
            <Container>
                <Row>
                    <Col xl={4} lg={4} md={6}>
                        <WalletBarcode user={props.user} hidePrintButton/>
                    </Col>
                </Row>
                <Row className="noprint">
                    <Col>
                        <h5>Click to Print out a paper version of your wallet that can be scanned in store instead of from your phone screen.</h5>
                    </Col>
                </Row>
                <Row className="noprint">
                    <Col>
                        <Button variant="success" onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Print</Button>&nbsp;
                        <Button onClick={() => setPrintMode(false)}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back</Button>
                    </Col>
                </Row>
            </Container> :
            <>
                <Container>
                    <Row>
                        <Col><h2>My Coupon Wallet</h2></Col>
                    </Row>
                    {
                        walletAlert != null &&
                        <Alert variant={walletAlertError ? "danger" : "success"}>{walletAlert}</Alert>
                    }                    
                    {
                        cognitoId != null &&
                        <>
                            <Row>
                                <Col xl={{span: 4, offset: 4}} lg={{span: 6, offset: 3}} md={{span: 8, offset: 2}} sm={{span: 12, offset: 0}} xs={12}>
                                    <WalletBarcode user={props.user} onPrintClick={handleOnPrintClick}/>
                                </Col>
                            </Row>
                            <WalletContents cognitoId={cognitoId} />
                        </>
                    }
                </Container>
            </>
    );
}

export default Wallet;